<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body class="mt-md-1">
        <b-overlay :show="isLoading" rounded="sm">
          <div class="m-1">
            <b-row>
              <b-col cols="8" md="8"><h3>{{modalTitle}}</h3></b-col>
              <b-col cols="4" md="4">
                <div class="text-right">
                  <b-button
                    v-if="updatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    size="sm"
                    class="ml-1"
                    @click="onShowReplaceThesisSubjects"
                  >
                    <span class="text-nowrap text-right d-none d-lg-block">
                      <feather-icon icon="FilterIcon" /> Lọc môn học thay thế KLTN
                    </span>
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.programmeSubjectSaveModal
                    variant="success"
                    size="sm"
                    class="ml-1"
                    @click="onCreateMainData"
                  >
                    <span class="text-nowrap text-right d-none d-lg-block">
                      <feather-icon icon="PlusIcon" /> Thêm môn học
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  styleClass="vgt-table striped bordered"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :fixed-header="true"
                  max-height="400px"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'"
                          :class="props.row.status?'text-success':'text-danger'"
                          v-html="getStatusName(props.row.status)"
                    ></span>
                    <span v-else-if="props.column.field === 'replaceThesis'"
                          :class="props.row.replaceThesis?'text-danger':'text-success'"
                          v-html="getThesisSubjectTypeName(props.row.replaceThesis)"
                    ></span>

                    <span v-else-if="props.column.field === 'countedForGpa'"
                          :class="props.row.countedForGpa?'text-success':'text-danger'"
                          v-html="getCountedForGpaName(props.row.countedForGpa)"
                    ></span>
                    <span v-else-if="props.column.field === 'isOptional'"
                          :class="props.row.isOptional?'text-danger':'text-success font-weight-bolder'"
                          v-html="getIsOptionalTypesName(props.row.isOptional)"
                    ></span>

                    <span v-else-if="props.column.field === 'totalCreditNum'">
                      {{ showCreditSummary(props.row) }}
                    </span>

                    <span v-else-if="props.column.field === 'action'" class="thead-group-action">
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          v-b-modal.programmeSubjectSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-button
                          v-if="deletable"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else-if="props.column.field === 'alterCode'" v-b-tooltip.hover.top="props.row.alterName">
                      {{ props.row.alterCode }}
                    </span>
                    <span v-else-if="props.column.field === 'conditionCode'" v-b-tooltip.hover.top="props.row.conditionName">
                      {{ props.row.conditionCode }}
                    </span>
                    <span v-else-if="props.column.field === 'groupCode'" v-b-tooltip.hover.top="props.row.groupName">
                      {{ props.row.groupCode }}
                    </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <ProgrammeSubjectSave
      ref="programmeSubjectSaveFormRef"
      :mainData="currentMainData"
      :ext-data="extMainData"
      @succeed="onSucceed"
    />
    <b-modal
      ref="modal-replace-thesis-subject"
      id="modal-replace-thesis-subject"
      ok-title="Chấp nhận & Lưu"
      cancel-title="Đóng"
      centered
      size="lg"
      title="Các môn học thay thế khóa luận tốt nghiệp(dự kiến) - HP trong nhóm tự chọn chưa đăng ký"
      hide-footer
    >
      <ReplaceThesisSubjects
        :ext-data="extMainData"
        @succeed="onSucceed"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import ProgrammeSubjectSave from '@/views/programme-subject/ProgrammeSubjectSave.vue'
import ReplaceThesisSubjects from '@/views/programme-subject/replace-thesis/ReplaceThesisSubjects.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'

export default {
  name: 'ProgrammeSubject',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ProgrammeSubjectSave,
    ReplaceThesisSubjects,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        organizationId: getUser().orgId,
        currentPage: 1,
        itemsPerPage: 10,
        programmeId: this.dataSend.id || this.$route.params.id,
        courseSemesterId: '',
        groupId: '',
        code: '',
        name: '',
        status: '',
        sort: '',
      },
      modalTitle: `Danh sách môn học của CTĐT - [${this.dataSend.code}] ${this.dataSend.name}`,
      currentMainData: undefined,
      extMainData: {
        parentId: this.dataSend.id || this.$route.params.id,
        courseId: this.dataSend.courseId,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'programmeSubject/dataLists',
      totalRows: 'programmeSubject/totalRows',
      statuses: 'programmeSubject/statuses',
      statusSymbols: 'programmeSubject/statusSymbols',
      countedForGpaTypes: 'programmeSubject/countedForGpaTypes',
      isOptionalTypes: 'programmeSubject/isOptionalTypes',
      resourceName: 'programmeSubject/resourceName',
      replaceThesisSubjectTypes: 'programmeSubject/replaceThesisSubjectTypes',
      subjectGroups: 'programmeSubjectGroup/dataLists',
      courseSemester: 'dropdown/courseSemesters',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    replaceThesisFilterDropdownItems() {
      return this.replaceThesisSubjectTypes.map(item => ({ value: item.value, text: item.label }))
    },
    groupFilterDropdownItems() {
      return this.subjectGroups.map(item => ({ value: item.id, text: item.name }))
    },
    semesterFilterDropdownItems() {
      return this.courseSemester.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tổng TC (LT_TH_TT)',
          field: 'totalCreditNum',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'HP thay thế',
          field: 'alterCode',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'HP điều kiện',
          field: 'conditionCode',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semesterName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.semesterFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          width: '100px',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tính điểm',
          field: 'countedForGpa',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Bắt buộc',
          field: 'isOptional',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Nhóm HP',
          field: 'groupCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.groupFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'TT KLTN',
          field: 'replaceThesis',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.replaceThesisFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.PROGRAMME_SUBJECTS)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.PROGRAMME_SUBJECTS)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.PROGRAMME_SUBJECTS)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getMainData(this.filter),
        this.getCourseSemester({ organizationId: getUser().orgId, courseId: this.dataSend.courseId, status: '', typeId: 1 }),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'programmeSubject/getData',
      deleteMainData: 'programmeSubject/deleteData',
      getCourseSemester: 'dropdown/getCourseSemesters',
    }),
    onCreateMainData() {
      this.currentMainData = undefined
    },
    onShowReplaceThesisSubjects() {
      this.$bvModal.show('modal-replace-thesis-subject')
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn rút bớt môn học <span class="text-danger">${mainData.name}</span> khỏi CTĐT này?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        size: 'xl',
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'groupCode')) this.updateParams({ groupId: columnFilters.groupCode })
      if (Object.hasOwn(columnFilters, 'semesterName')) this.updateParams({ courseSemesterId: columnFilters.semesterName })
      if (Object.hasOwn(columnFilters, 'replaceThesis')) this.updateParams({ replaceThesis: columnFilters.replaceThesis })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statusSymbols.find(status => status.value === id).label
    },
    getThesisSubjectTypeName(id) {
      return this.replaceThesisSubjectTypes.find(status => status.value === id).label
    },
    getCountedForGpaName(id) {
      return this.countedForGpaTypes.find(item => item.value === id).label
    },
    getIsOptionalTypesName(id) {
      return this.isOptionalTypes.find(item => item.value === id).label
    },
    showCreditSummary(row) {
      const summary = `${row.totalCreditNum} (${
        row.theoryCreditNum}_${
        row.practiceCreditNum}_${
        row.internshipCreditNum})`
      return summary
    },
    async onSucceed() {
      await this.getMainDataFromStore()
      this.$bvModal.hide('modal-replace-thesis-subject')
    },
  },
  mounted() {
    const grid = document.querySelector('.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
}
</script>
